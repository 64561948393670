import Lie from "./lie"

const lies: Lie[] = require("../data/lies.json")

export function getLies() {
    return lies
}

export function randomLie(): Lie  {
    return  lies[Math.floor(Math.random() * lies.length)];
}
